import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { POPULAR_AREAS } from 'dpl/shared/constants/shelters';
import {
  FancyDropdownMenu,
  FancyDropdownMenuItem
} from 'dpl/components/FancyDropdown';
import SearchNoResults from 'dpl/components/Search/SearchNoResults';
import Icon from 'dpl/common/components/Icon';

export function LocationResultsResultGoogleItem({ place, onClick }) {
  return (
    <a
      href="#"
      className="db w-100 flex items-center nh3 ph3 nh0-md ph2-md pv3 pv2-md bw0-l bw0-m bb b--gray-300 font-14"
      onClick={e => {
        e.preventDefault();
        onClick(place);
      }}
    >
      <span className="ph3 pv3 bg-light-gray br3 bg-gray-400 mr2">
        <Icon
          name="fetch-location"
          className="white"
          width="24px"
          height="24px"
        />
      </span>
      {place.name}
    </a>
  );
}

LocationResultsResultGoogleItem.propTypes = {
  place: PropTypes.shape({
    name: PropTypes.string.isRequired,
    getCoordinates: PropTypes.func.isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired
};

export function LocationResultsResultAreaItem({ area, onClick }) {
  return (
    <a
      className="db w-100 flex items-center nh3 ph3 nh0-md ph2-md pv3 pv2-md bw0-l bw0-m bb b--gray-300 font-14"
      href={area.url}
      onClick={e => {
        e.preventDefault();
        onClick(area);
      }}
    >
      <span className="ph3 pv3 bg-light-gray br3 bg-gray-400 mr2">
        <Icon
          name="fetch-location"
          className="white"
          width="24px"
          height="24px"
        />
      </span>
      {area.name}
    </a>
  );
}

LocationResultsResultAreaItem.propTypes = {
  area: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired
};

export default function LocationResultsList({
  googlePlacesResults,
  query,
  onSelectItem
}) {
  return (
    <FancyDropdownMenu
      className="LocationSearchResultsList overflow-y-auto br3 bg-white mt2"
      contentClassName="ph0"
    >
      {(() => {
        if (!query) {
          return (
            <Fragment>
              <p className="tertiary lg:font-12 font-14 fw-medium pv2 ph4">
                Popular areas
              </p>
              {POPULAR_AREAS.map(area => (
                <FancyDropdownMenuItem
                  key={area.url}
                  itemKey={area.url}
                  selectedClassName="bg-subtle-light-gray"
                >
                  <LocationResultsResultAreaItem
                    area={area}
                    onClick={onSelectItem}
                  />
                </FancyDropdownMenuItem>
              ))}
            </Fragment>
          );
        }

        return googlePlacesResults.length === 0 ? (
          <SearchNoResults query={query} />
        ) : (
          googlePlacesResults.map((place, idx) => (
            <FancyDropdownMenuItem
              key={idx}
              itemKey={idx}
              selectedClassName="bg-subtle-light-gray"
            >
              <LocationResultsResultGoogleItem
                place={place}
                onClick={onSelectItem}
              />
            </FancyDropdownMenuItem>
          ))
        );
      })()}
    </FancyDropdownMenu>
  );
}

LocationResultsList.propTypes = {
  query: PropTypes.string,
  googlePlacesResults: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      getCoordinates: PropTypes.func.isRequired
    })
  ).isRequired,
  onSelectItem: PropTypes.func.isRequired
};

LocationResultsList.defaultProps = {
  query: null
};
