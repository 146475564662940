import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withHeadTags from 'dpl/decorators/withHeadTags';
import { GOOGLE_MAPS_API_URL } from 'dpl/shared/constants/urls';

const DEFAULT_OPTIONS = {
  types: ['(regions)'],
  componentRestrictions: { country: 'us' },
  sessionToken: null
};

export default function withGooglePlaces(WrappedComponent) {
  class GooglePlacesComponent extends Component {
    static propTypes = {
      query: PropTypes.string.isRequired,
      isOpen: PropTypes.bool
    };

    static defaultProps = {
      isOpen: false
    };

    state = {
      results: []
    };

    services = null;

    componentDidMount() {
      this.initIfNeeded();
    }

    componentDidUpdate(prevProps) {
      this.initIfNeeded();
      if (
        this.services &&
        (this.props.query !== prevProps.query ||
          (this.props.isOpen && !prevProps.isOpen))
      ) {
        this.updateResults();
      }
    }

    updateResults() {
      if (!this.props.query) {
        this.setState({ results: [] });
        return;
      }

      this.services.autocomplete.getPredictions(
        {
          ...DEFAULT_OPTIONS,
          input: this.props.query
        },
        results => {
          if (!results) {
            this.setState({ results: [] });
            return;
          }

          this.setState({
            results: results.map(({ description, place_id: placeId }) => ({
              name: description,
              getCoordinates: () => this.getCoordinatesForPlace(placeId)
            }))
          });
        }
      );
    }

    getCoordinatesForPlace = placeId =>
      new Promise(res => {
        this.services.places.getDetails(
          {
            ...DEFAULT_OPTIONS,
            placeId
          },
          ({ formatted_address: displayName, geometry: { location } }) => {
            res({
              lat: location.lat(),
              lng: location.lng(),
              displayName
            });
          }
        );
      });

    initIfNeeded() {
      if (!this.services && window.google) {
        DEFAULT_OPTIONS.sessionToken ||
          (DEFAULT_OPTIONS.sessionToken =
            new window.google.maps.places.AutocompleteSessionToken());

        this.services = {
          autocomplete: new window.google.maps.places.AutocompleteService(),
          places: new window.google.maps.places.PlacesService(
            document.createElement('div')
          )
        };
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          googlePlacesResults={this.state.results}
          getCoordinatesForPlace={this.getCoordinatesForPlace}
        />
      );
    }
  }

  return withHeadTags(() => [
    {
      name: 'script',
      noRemove: true,
      attributes: {
        src: GOOGLE_MAPS_API_URL,
        defer: true,
        async: true
      }
    }
  ])(GooglePlacesComponent);
}
